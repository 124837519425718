import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { SecurityStatementsInterface } from '../models/securitystatements';

export interface SecurityStatementsState {
  model: SecurityStatementsInterface;
}

const initialState: SecurityStatementsState = {
  model: {
    route: 'ck_securitystatement',
    fullCkiProcess: false,
    messages: {},
    encryptedDeeplink: null,
    showHealthRegulation: false,
  },
};

export const securityStatementsSlice = createSlice({
  name: 'securityStatement',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSecurityStatementsModel: (state, action) => {
      state.model = action.payload;
    },
    setSecurityStatementsModelAttribute: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
  },
});

export const {
  setSecurityStatementsModel,
  setSecurityStatementsModelAttribute,
} = securityStatementsSlice.actions;

export const selectSecurityStatementsModel = (state: RootState) => state.securitystatements.model;

export default securityStatementsSlice.reducer;
