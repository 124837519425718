import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { parseURL } from '../utils/utils';
export interface AppState {
  isLoading: boolean; // Use to control the Loader icon spining.
  isInApp: boolean;
  appid: string | null;
  isKiosk: boolean;
  kiosk: string | null;
  isInGroupApp: boolean;
  vettingStatusFail: boolean;
  currentPage: string; // Track what is the current page
  previousPage: string; // Previously Visited page
  appToken: string;
  disableApisNavigation: boolean;
  URLData: any;
  isDeeplink: boolean;
  fullCkiProcess: boolean; // Is this a re-retrieve process
  adcConfirmed: boolean;
  langCode: string;
  customerCode: string;
  expiryTime: number;
  locationStateData: any;
  platform: string;
}

const initialState: AppState = {
  isLoading: false,
  isInApp: false,
  appid: null,
  isKiosk: false,
  kiosk: null,
  isInGroupApp: false,
  vettingStatusFail: false,
  currentPage: '',
  previousPage: '',
  appToken: '',
  disableApisNavigation: false,
  URLData: {},
  isDeeplink: false,
  fullCkiProcess: true,
  adcConfirmed: false,
  langCode: 'en',
  customerCode: '',
  expiryTime: 0,
  locationStateData: null,
  platform: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetApp: (state) => {
      return initialState;
    },
    clearAppResourceData: (state) => {
      state.langCode = 'en';
      state.customerCode = '';
      state.expiryTime = 0;
    },
    initAppDataAtRetrieve: (state) => {
      state.isLoading = false;
      state.appToken = '';
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInApp: (state, action: PayloadAction<boolean>) => {
      state.isInApp = action.payload;
    },
    setAppId: (state, action: PayloadAction<string | null>) => {
      state.appid = action.payload;
    },
    setIsKiosk: (state, action: PayloadAction<boolean>) => {
      state.isKiosk = action.payload;
    },
    setKiosk: (state, action: PayloadAction<string | null>) => {
      state.kiosk = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    setVettingStatusFail: (state, action: PayloadAction<boolean>) => {
      state.vettingStatusFail = action.payload;
    },
    setAppToken: (state, action: PayloadAction<string>) => {
      state.appToken = action.payload;
    },
    setApisNavigation: (state, action: PayloadAction<boolean>) => {
      state.disableApisNavigation = action.payload;
    },
    setDeepLink: (state, action: PayloadAction<boolean>) => {
      state.isDeeplink = action.payload;
    },
    setURLData: (state, action: PayloadAction<any>) => {
      state.URLData = action.payload;
    },
    clearURLDataExcept: (state, action: PayloadAction<any>) => {
      let excepts = action.payload;
      let keys = Object.keys(state.URLData);
      keys.forEach((key: string) => {
        if (excepts.indexOf(key) === -1) {
          delete state.URLData[key];
        }
      });
    },
    setLangCode: (state, action: PayloadAction<string>) => {
      state.langCode = action.payload;
    },
    parseURLtoState: (state, action: PayloadAction<string>) => {
      const { normalizedURLParams, isDeeplink } = parseURL(action.payload) || {};
      state.URLData = normalizedURLParams;
      state.isDeeplink = isDeeplink;
    },
    setAdcConfirmed: (state, action: PayloadAction<boolean>) => {
      state.adcConfirmed = action.payload;
    },
    setCustomerCode: (state, action: PayloadAction<string>) => {
      state.customerCode = action.payload;
    },
    setExpireTime: (state, action: PayloadAction<number>) => {
      state.expiryTime = action.payload;
    },
    setLocationStateData: (state, action: PayloadAction<any>) => {
      state.locationStateData = action.payload;
    },
    setPlatform: (state, action: PayloadAction<any>) => {
      state.platform = action.payload;
    },
  },
});

export const {
  resetApp,
  clearAppResourceData,
  initAppDataAtRetrieve,
  setIsLoading,
  setIsInApp,
  setAppId,
  setIsKiosk,
  setKiosk,
  setCurrentPage,
  setVettingStatusFail,
  setAppToken,
  setApisNavigation,
  setDeepLink,
  setURLData,
  clearURLDataExcept,
  parseURLtoState,
  setLangCode,
  setAdcConfirmed,
  setExpireTime,
  setCustomerCode,
  setLocationStateData,
  setPlatform,
} = appSlice.actions;

export const selectIsInApp = (state: RootState) => state.app.isInApp;
export const selectisKiosk = (state: RootState) => state.app.isKiosk;
export const selectCurrentPage = (state: RootState) => state.app.currentPage;
export const selectIsInGroupApp = (state: RootState) => state.app.isInGroupApp;
export const selectAppToken = (state: RootState) => state.app.appToken;
export const selectIsLoading = (state: RootState) => state.app.isLoading;
export const selectLangCode = (state: RootState) => state.app.langCode;
export const selectCustomerCode = (state: RootState) => state.app.customerCode;
export const selectExpireTime = (state: RootState) => state.app.expiryTime;
export const selectPlatform = (state: RootState) => state.app.platform;

export default appSlice.reducer;
