import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { FlightPathItemInterface } from '../models/flightpath';

export interface FlightPathState {
  model: FlightPathItemInterface[];
}

const initialState: FlightPathState = {
  model: [],
};

export const FlightPathSlice = createSlice({
  name: 'flightPath',
  initialState,
  reducers: {
    setFlightPathModel: (state, action) => {
      state.model = action.payload;
    },
  },
});

export const {
  setFlightPathModel,
} = FlightPathSlice.actions;

export const selectFlightPath = (state: RootState) => state.flightpath.model;

export default FlightPathSlice.reducer;
