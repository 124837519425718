import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  ShoppingBasketInterface,
  ShoppingBasketSelectionItem,
} from '../models/shoppingBasket';

export interface ShoppingBasketState {
  model: ShoppingBasketInterface;
}

const initialState: ShoppingBasketState = {
  model: {
    alreadyPrepared: false,
    selection: [],
    totalAmount: 0.0,
    currency: 'EUR',
  },
};

export const shoppingBasketSlice = createSlice({
  name: 'ShoppingBasket',
  initialState,
  reducers: {
    setShoppingBasketModel: (state, action) => {
      state.model = action.payload;
    },
    setShoppingBasketModelAttributes: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    removeSelectionFromShoppingBasket: (
      state,
      action: PayloadAction<number>
    ) => {
      state.model.selection = state.model.selection.filter(
        (elem: ShoppingBasketSelectionItem) => {
          return elem.flightId !== action.payload;
        }
      );
      let newTotalAmount = 0;
      state.model.selection.forEach((elem: ShoppingBasketSelectionItem) => {
        newTotalAmount += elem.seatPrice * elem.numberOfSeats;
      });
      state.model.totalAmount = newTotalAmount;
    },
  },
});

export const {
  setShoppingBasketModel,
  setShoppingBasketModelAttributes,
  removeSelectionFromShoppingBasket,
} = shoppingBasketSlice.actions;

export const selectShoppingBasketModel = (state: RootState) =>
  state.shoppingbasket.model;

export default shoppingBasketSlice.reducer;
