import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// Ancillary only exist on eedpt-swr-custom?
export interface AncillaryState {
  model: any;
}

const initialState: AncillaryState = {
  model: {
    amount: 0,
    currency: null,
    type: 'upgrade',
    upgrade: true,
  },
};

export const ancillarySlice = createSlice({
  name: 'ancillary',
  initialState,
  reducers: {
    setAncillaryModel: (state, action) => {
      state.model = action.payload;
    },
    updateAncillaryModel: (state, action) => {
      state.model = Object.assign(state.model, action.payload);
    },
  },
});

export const { setAncillaryModel, updateAncillaryModel } = ancillarySlice.actions;

export const selectAncillaryModel = (state: RootState) => state.ancillary.model;
export default ancillarySlice.reducer;
