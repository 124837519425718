import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../app/store';
import { PassengerInterface } from '../models/passenger';
import {
  SeatInterface,
  SeatmapInterface,
  SeatmapModelInterface,
  SeatRowInterface,
} from '../models/seatmap';
import {
  getActiveFlightPaxes,
  getCurrentlySelectedPax,
  getPaxesEligibleForSeatChange,
} from '../utils/passengerHelper';

export interface SeatmapState {
  model: SeatmapModelInterface;
  exitSeatModal: any;
}

const initialState: SeatmapState = {
  model: {
    excludeFromLocalStorage: ['apicall'],
    route: 'ck_flightseatmap',
    flights: [],
    rows: {},
    fullCkiProcess: true,
    messages: {},
    seatmapForClass: 'Y',
    seatmapForOperatingType: 'ECONOMY_CLASS',
    chargeableSeatmap: false,
    priceInfo: {},
    seatAvailabilityInfo: {},
    currency: null,
    shoppingBasketData: null,
    seatmapFlightNotification: [],
    priceInfoModel: {},
    seatAvailabilityInfoModel: {},
    activeFlightId: 1,
    activeFlightIsChargeable: false,
    activePaxId: 1,
    rowsInfo: {
      maxSeatsPerRow: 6,
      rowConfigs: {
        ECONOMY_CLASS: {
          M: { '1': { configId: 1, configHeaders: 'ABC DEF' } },
        },
      },
      seatsConfirmed: [],
      seatsToConfirm: {},
      blockedSeats: [],
      isRichSeatmap: false,
      seatmapClass: 'seatmap--seats-generic',
      hasSeatmapSubClass: false,
      hasAdditonalSubClass: false,
    },
    rowConfigs: [],
    rowsAsCollection: [],
  },
  exitSeatModal: {
    isOpen: false,
    seatToChoose: '',
  },
};

export const seatmapSlice = createSlice({
  name: 'seatmap',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSeatmapModel: (state) => {
      return initialState;
    },
    setSeatmapModel: (state, action) => {
      state.model = action.payload;
    },
    setSeatmapModelAttribute: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    setActivePax: (state, action: PayloadAction<number>) => {
      let paxid = action.payload;
      let activeFlightPaxes = getActiveFlightPaxes(state.model);

      activeFlightPaxes.forEach((pax) => {
        pax.isActive = false;
        if (pax.id === paxid) pax.isActive = true;
      });

      // just in case set the active pax id if someone decides to click pax out of order
      state.model.activePaxId = paxid;
    },
    changeSeatStatus: (state, action: PayloadAction<{ seat: SeatInterface; value: string }>) => {
      let seat = action.payload.seat;
      let value = action.payload.value;

      // First we need to find that seat in the model
      if (state.model.rowsAsCollection) {
        let seatRows = state.model.rowsAsCollection[seat.configId];
        if (!seatRows) {
          return;
        }
        let foundRow = _.find(seatRows, {
          rowPosition: seat.seatNumberDigit,
        }) as SeatRowInterface;
        if (!foundRow) {
          return;
        }
        let foundSeat = _.find(foundRow.seatArray, {
          seatNumber: seat.seatNumber,
        });
        // Then we update the data
        if (foundSeat) {
          foundSeat.computedStatus = value;
        }
      }
    },
    changePaxSeat: (state, action: PayloadAction<{ paxId: number; value: string }>) => {
      let paxId = action.payload.paxId;
      let value = action.payload.value;
      let activeFlightPaxes = getActiveFlightPaxes(state.model);
      let foundPax = _.find(activeFlightPaxes, { id: paxId });
      if (foundPax) {
        foundPax.selectedSeatReference = value;
      }
    },
    setRowConfigs: (state) => {
      let model = state.model;
      let currentClass = model.rowsInfo.rowConfigs[model.seatmapForOperatingType];
      let rowConfigs: (string[] | null)[] = [];
      let rowConfigsUpper = [];
      // get the headers for the main deck
      _.each(currentClass.M, function (rowConfig) {
        let configHeaderArray = rowConfig.configHeaders.split(''); // ['A','B','C']
        let configHeaderDictArray = []; // [{0: 'A'},{0: 'B"},{ 0: 'C'}]
        for (let i = 0; i < configHeaderArray.length; i++) {
          configHeaderDictArray[i] = _.zipObject([0], _.trim(configHeaderArray[i]));
        }
        rowConfigs[rowConfig.configId] = configHeaderArray;
      });
      model.rowConfigs = rowConfigs;

      // and the upper deck
      // check if there is an upper deck first
      if (currentClass.U) {
        _.each(currentClass.U, function (rowConfig) {
          let configHeaderArray = rowConfig.configHeaders.split(''); // ['A','B','C']
          let configHeaderDictArray = []; // [{0: 'A'},{0: 'B"},{ 0: 'C'}]
          for (let i = 0; i < configHeaderArray.length; i++) {
            configHeaderDictArray[i] = _.zipObject([0], _.trim(configHeaderArray[i]));
          }
          rowConfigsUpper[rowConfig.configId] = configHeaderArray;
        });
      }
      // TODO: Why rowConfigsUpper is not used? Why assign rowConfigs again?
      model.rowConfigs = rowConfigs;
    },
    /**
     * Set if we're working with economy or business class, depending on the class of active pax.
     */
    setSeatmapWorkingState: (state) => {
      let model = state.model;
      let foundFlight = _.find(model.flights, { isActive: true });
      if (!foundFlight) {
        return;
      }
      let paxesForTransaction = foundFlight.passengers;
      let activePax = getCurrentlySelectedPax(paxesForTransaction);
      let seatmapMode = 'normal';
      let double = false;
      let seatmapArray = [];
      let currentDeckUpper = false;
      let deck = !_.isUndefined(activePax) ? activePax.seatDeck : 'M';
      // If we failed to get the active passenger by status, just take the
      // first passenger on the flight for failsafe
      if (_.isUndefined(activePax) && paxesForTransaction.length > 0) {
        activePax = paxesForTransaction[0];
      }

      // Set the seatmap mode
      if (activePax && activePax.isDisabledOnSeatmap) {
        seatmapMode = 'disabled';
      } else if (activePax && activePax.infant.length > 0) {
        seatmapMode = 'infant';
      } else {
        seatmapMode = 'normal';
      }

      let activeSeatmap = model.rows[model.seatmapForOperatingType][deck];
      seatmapArray = mapSeatArray(activeSeatmap);
      model.rowsAsCollection = seatmapArray;

      let numberOfDecks = Object.keys(model.rows[model.seatmapForOperatingType]).length;

      if (numberOfDecks === 2) {
        double = true;
      }

      if (deck === 'U') {
        currentDeckUpper = true;
      }

      if (model.rowsInfo.isRichSeatmap && !model.rowsInfo.hasSeatmapSubClass) {
        // This is a rich seatmap that has to show all classes so go
        // through all remaining classes and put some extra field to
        // mark the seats
        let remainingRows: any;
        _.forEach(model.rowConfigs, function (value: any, key: string) {
          if (key) {
            let remainingClasses = _.omit(model.rows, model.seatmapForOperatingType);

            _.forEach(remainingClasses, function (remainingClass) {
              _.forEach(remainingClass, function (deck) {
                _.forEach(deck, function (seatmap) {
                  _.forEach(seatmap, function (row) {
                    _.forEach(row, function (seat) {
                      seat.richClassDisabled = true;
                    });
                  });
                });
              });
            });

            remainingRows = _.reduce(
              remainingClasses,
              function (memo, remainingClass) {
                let res = _.extend(memo, remainingClass[deck]);
                return res;
              },
              {}
            );
            if (remainingRows) {
              _.extend(activeSeatmap[key], remainingRows[key]);
            }
          }
        });
      }

      model.seatmapMode = seatmapMode;
      model.double = double;
      model.currentDeckUpper = currentDeckUpper;
    },
    processSeatmapCollection: (state) => {
      let model = state.model;
      let data = getSeatmapDataObject(model);
      let seatmapCollection = model.rowsAsCollection;
      _.forEach(seatmapCollection, function (seatmap) {
        if (seatmap) {
          seatmap.forEach((seatRow) => {
            let seatArray = seatRow.seatArray;
            let firstSeat = seatArray[0];
            let lastSeat = seatArray[seatArray.length - 1];
            // Add helper attribute to each seatRow
            Object.assign(seatRow, {
              rowPosition: firstSeat.seatNumberDigit, // may need to change name
              isWing: firstSeat.isWing,
              isFirstWing: _.includes(firstSeat.seatIndicators, 'FIRSTWING'),
              isExitLeft: firstSeat.nextToExit,
              isExitRight: lastSeat.nextToExit,
              isRichSeatmap: model.rowsInfo.isRichSeatmap,
            });

            seatArray.forEach((seatModel) => {
              processSeats(model, seatModel, data);
            });
          });
        }
      });
    },
    setActiveDeck: (
      state,
      action: PayloadAction<{
        activeDeck: string;
        activePax: PassengerInterface;
      }>
    ) => {
      let activeDeck = action.payload.activeDeck;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let activePax = action.payload.activePax;
      let model = state.model;

      let activeSeatmap = model.rows[model.seatmapForOperatingType][activeDeck];
      let seatmapArray = mapSeatArray(activeSeatmap);
      model.rowsAsCollection = seatmapArray;
      model.currentDeckUpper = activeDeck === 'U';
      processSeatmapCollection();
    },
    setSeatmapPaxAttribute: (
      state,
      action: PayloadAction<{ paxId: number; key: string; value: any }>
    ) => {
      let paxId = action.payload.paxId;
      const key = action.payload.key;
      const value = action.payload.value;
      let activeFlightPaxes = getActiveFlightPaxes(state.model);
      let foundPax = _.find(activeFlightPaxes, { id: paxId });
      if (foundPax) {
        Object.assign(foundPax, { [key]: value });
      }
    },
    setExitSeatModalAttribute: (
      state,
      action: PayloadAction<{ isOpen: boolean; seatToChoose?: any }>
    ) => {
      state.exitSeatModal.isOpen = action.payload.isOpen;
      if (action.payload.seatToChoose) {
        state.exitSeatModal.seatToChoose = action.payload.seatToChoose;
      }
    },
  },
});

export const {
  setSeatmapModel,
  setSeatmapModelAttribute,
  setActivePax,
  resetSeatmapModel,
  changeSeatStatus,
  changePaxSeat,
  setRowConfigs,
  setSeatmapWorkingState,
  processSeatmapCollection,
  setActiveDeck,
  setExitSeatModalAttribute,
  setSeatmapPaxAttribute,
} = seatmapSlice.actions;

export const selectSeatmapModel = (state: RootState) => state.seatmap.model;
export const selectExitSeatModal = (state: RootState) => state.seatmap.exitSeatModal;
export default seatmapSlice.reducer;

function mapSeatArray(activeSeatmap: SeatmapInterface) {
  let rowsArray: any = [];
  let seatmapArray: any = [];
  _.forEach(activeSeatmap, function (seatmap, configId) {
    if (configId !== '0') {
      _.toArray(seatmap).forEach((item) => {
        rowsArray.push({ seatArray: item });
      });
      seatmapArray[parseInt(configId)] = rowsArray;
    }
  });

  return seatmapArray;
}

function processSeats(model: SeatmapModelInterface, seatModel: SeatInterface, data: any) {
  let seatStatus = seatModel.status,
    computedStatus = seatStatus,
    disabled = false,
    noWindowSeats = ['38A', '38F', '39F'],
    noWindowSeatPlaneTypes = ['a320-max', 'a321-max'];

  switch (data.seatmapMode) {
    case 'normal':
      // nothing to see here
      break;
    case 'infant':
      if (seatStatus === 'available' && !seatModel.infant) {
        computedStatus = 'occupied';
        disabled = true;
      }
      break;
    case 'disabled':
      if (seatStatus === 'available') {
        computedStatus = 'available-disabled';
        disabled = true;
      }
      break;
  }

  if (
    seatStatus !== 'available' &&
    !_.includes(data.assignedSeatReferences, seatModel.seatNumber)
  ) {
    disabled = true;
  }

  if (_.includes(data.assignedSeatReferences, seatModel.seatNumber) && !model.readOnly) {
    disabled = false;
    computedStatus = 'active';
  }

  // check plane types ( only A-320-max and A-321-max should have this )
  if (_.includes(noWindowSeatPlaneTypes, model.rowsInfo.seatmapClass)) {
    // if any of the seat numbers match this is no window seat
    if (_.includes(noWindowSeats, seatModel.seatNumber)) {
      seatModel.noWindowSeat = true;
    }
  }

  Object.assign(seatModel, {
    seatOccupied: computedStatus === 'occupied',
    seatAssigned: computedStatus === 'active',
    computedStatus: computedStatus,
    disabled: disabled,
    isRichSeatmap: model.rowsInfo.seatmapClass,
    tmpSeatNumber: seatModel.seatNumber.toLowerCase().replace(/^\s*[0]/g, ''),
  });
}

function getSeatmapDataObject(model: SeatmapModelInterface) {
  let activeFlightPaxes = getActiveFlightPaxes(model);
  return {
    assignedSeatReferences: _.map(
      getPaxesEligibleForSeatChange(activeFlightPaxes),
      'selectedSeatReference'
    ),
    seatmapMode: model.seatmapMode,
  };
}
