import _ from 'lodash';
import { CountryResource, LocalizationResource } from '../services/resource/ResourceInterfaces';
// Please avoid using redux stuff in here as it make unit test not work (because of circular dependencies)

const URLToObject = (url: any) => {
  var request: any = {};

  var pairs = url.substring(url.indexOf('?') + 1).split('&');

  if (pairs.length > 0 && pairs[0] !== '') {
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }

  return request;
};

const checkIfRunningInContainer = (): { appId: string; kiosk: string; platform: string } => {
  // parse the URL and set initial state values
  let urlParams = URLToObject(window.location.search);
  let appId = '';
  let kiosk = '';
  let platform = '';

  if (urlParams.appid) {
    appId = urlParams.appid;
  }

  if (urlParams.kiosk === 'true') {
    kiosk = urlParams.kiosk;
  }

  if (urlParams.platform) {
    platform = urlParams.platform;
  }

  return { appId, kiosk, platform };
};

/** Customizer for lodash mergeWith(), avoid overwrite attributes on
 * `objValue` with same name attributes from `srcValue`.
 *
 * More practical example:
 *
 * `model = _.mergeWith(model, initModel, noOverwriteCustomizer)`
 *
 * This will guanratee all attributes in `model` will not be overwrote, while
 * also copying non-duplicated attributes from `initModel` to `model`.
 */
const noOverwriteCustomizer = (objValue: any, srcValue: any) => {
  if (typeof srcValue === 'object') {
    _.mergeWith(objValue, srcValue, noOverwriteCustomizer);
  } else if (objValue) {
    return objValue;
  } else {
    return srcValue;
  }
};

/**
 * Create query string for request with a object.
 *
 * Example: /api/get-something?subtype=email&type=single_mbp
 * @param data JSON object
 * @returns queryString
 */
function createQueryString(data: any) {
  let queryString = Object.keys(data)
    .map(function (key) {
      return key + '=' + data[key];
    })
    .join('&');
  return queryString;
}

function getFormattedFqtvPrograms(fqtvPrograms: any[]) {
  let formattedFqtvPrograms = {};

  fqtvPrograms.forEach((fqtvProgram: any) => {
    let obj: any = {};
    obj[fqtvProgram.code] = fqtvProgram.name;
    formattedFqtvPrograms = _.extend(formattedFqtvPrograms, obj);
  });

  if (fqtvPrograms) {
    return formattedFqtvPrograms;
  } else {
    return {};
  }
}

function getMessageTypes(messages: any) {
  type MessageStatus = 'error' | 'warning' | 'success';
  let messageKeys = _.keys(messages);
  let types: MessageStatus[] = [];
  //   {
  //     "error": {
  //         "addpax_all_checked_in": "CKI_ADDPAX_ALL_PAX_CHECKED_IN"
  //     },
  //     "warning": {
  //         "any_any_any_any_croatia_any_any_ck_selectflight":
  //         "ANY_ANY_ANY_ANY_Croatia_ANY_ANY_CK_SELECTFLIGHT"
  //     }
  //   }
  if (_.includes(messageKeys, 'error')) {
    types.push('error');
  }

  if (_.includes(messageKeys, 'warning')) {
    types.push('warning');
  } else if (_.includes(messageKeys, 'success')) {
    types.push('success');
  }

  if (types.length === 0) {
    // Default value
    types.push('error');
  }
  return types;
}

function getCurrentPage() {
  let url = window.location.pathname;
  const match = url.match(/ck_(.*)/);
  let res = match ? match[0] : '';
  return res;
}

/**
 * Get all states of a country.
 * @param countryCode
 * @returns
 */
function getStateOfCountry(countryCode: string, countries: CountryResource[]) {
  if (_.isUndefined(countryCode) || _.isEmpty(countryCode)) {
    return [];
  }

  let country = _.find(countries, { code: countryCode });
  if (_.isUndefined(country)) {
    return [];
  }
  return country.states;
}

function objToArrSelectBoxCollection(objectCollection: any) {
  let arrayCollection = [];
  for (let value in objectCollection) {
    // http://stackoverflow.com/questions/1963102/what-does-the-jslint-error-body-of-a-for-in-should-be-wrapped-in-an-if-statemen
    if (objectCollection.hasOwnProperty(value)) {
      let label = objectCollection[value];

      arrayCollection.push({
        value: value,
        name: label,
      });
    }
  }
  return arrayCollection;
}

function buildHometagsSelectbox(start: any, limit: any) {
  let options: any = {};
  _.times(limit - start, function (index: any) {
    let option = parseInt(start, 10) + parseInt(index, 10);
    options[option.toString()] = option;
  });
  return options;
}

function areWeInTestMode(): boolean {
  return process.env.JEST_WORKER_ID !== undefined;
}

const getInvalidFieldErrorResponse = (response: any, localizations: LocalizationResource) => {
  let key = Object.keys(response.data.invalidFields)[0];
  let errorCode = response.data.invalidFields[key];

  // If error response is deeply nested, keep drilling to get the first one
  while (!_.isString(errorCode) && !_.isUndefined(errorCode)) {
    let errorObject = errorCode;
    key = Object.keys(errorObject)[0];
    errorCode = errorObject[key];
  }

  if (_.isUndefined(errorCode)) {
    return '';
  }

  let errorMessage: string = localizations['error'][errorCode];
  if (_.isUndefined(errorMessage)) {
    errorMessage = errorCode;
  }
  return errorMessage;
};

const getOperatingSystem = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('ipad') > -1 ||
    userAgent.indexOf('ipod') > -1
  ) {
    return 'ios';
  }
  if (
    userAgent.indexOf('macintosh') > -1 &&
    userAgent.indexOf('safari') > -1 &&
    userAgent.indexOf('chrome') === -1
  ) {
    return 'ios'; // It's actually 'macos' but function-wise they are same as ios anyway
  }
  // If not iOS or macOS, then return android (even if the user can be windows or other os)
  return 'android';
};

const DEEPLINK_URL_MAPPINGS = {
  langCode: 'langCode',
  first_name1: 'firstName',
  last_name1: 'lastName',
  departure_port: 'departureAirport',
  departure_date: 'dayOfDeparture',
  flight_number: function (urlParams: any) {
    let carrier = urlParams.carrier || '';
    let output = ['flightNumber', carrier + urlParams.flight_number];
    return output;
  },
  paxflight: 'retrievalData',
  etkt: 'etix',
};

const DEEPLINK_CONDITIONS = ['lastName', 'retrievalData'];

/**
 * Normalize the key name in URL data using a map.
 * @param urlData
 * @param urlMappings
 * @returns
 */
const normalizeURLData = function (urlData: any, urlMappings: any) {
  if (_.isEmpty(urlMappings)) {
    return urlData;
  }

  let normalizedURL = _.reduce(
    urlData,
    function (result: any, value: string, key: string, urlData: any) {
      let mapKey = '';

      if (urlMappings[key]) {
        // Currently, only flight_number in mapping is a function
        if (_.isFunction(urlMappings[key])) {
          // Run the function with [urlData] as arguments
          mapKey = urlMappings[key](...[urlData]);
          result[mapKey[0]] = mapKey[1];
        } else {
          mapKey = urlMappings[key];
          result[mapKey] = value;
        }
      } else {
        result[key] = value;
      }

      return result;
    },
    {}
  );

  return normalizedURL;
};

const parseURL = (queryString: string): any => {
  // parse the URL and set initial state values
  let urlParams = URLToObject(queryString);
  let isDeeplink = false;
  if (_.isEmpty(urlParams)) {
    // Nothing to parse
    return { normalizedURLParams: {}, isDeeplink };
  }
  // Parse different types of URL to eeStructure
  let normalizedURLParams = normalizeURLData(urlParams, DEEPLINK_URL_MAPPINGS);

  if (!_.isEmpty(normalizedURLParams)) {
    let keys = _.keys(normalizedURLParams);
    // Check for deeplink condition
    // extend the check for deeplink multiple conditions
    _.each(DEEPLINK_CONDITIONS, function (deeplinkCondition) {
      if (_.includes(keys, deeplinkCondition)) {
        isDeeplink = true;
      }
    });
  }
  return { normalizedURLParams, isDeeplink };
};

const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/**
 * Detect the user platform based on user Agent (so parameter such as appId and platform won't matter).
 * Return true if user is Desktop.
 */
const trueDetectDesktop = (): boolean => {
  const userAgent = navigator.userAgent;
  const isMobile = /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry/i.test(userAgent);
  return !isMobile;
};

export {
  URLToObject,
  checkIfRunningInContainer,
  noOverwriteCustomizer,
  createQueryString,
  getFormattedFqtvPrograms,
  getMessageTypes,
  getCurrentPage,
  getStateOfCountry,
  objToArrSelectBoxCollection,
  buildHometagsSelectbox,
  areWeInTestMode,
  getInvalidFieldErrorResponse,
  getOperatingSystem,
  parseURL,
  openInNewTab,
  trueDetectDesktop
};
