import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface CountdownState {
  model: {
    // Time in seconds
    startTime: number;
    // Time in seconds when re-set timer is offered
    offerExtendTime: number;
    // Time in seconds when timer is shown on the screen
    showTimerTime: number;
    secondsRemaining: number;
    // Show the message and the extend button
    showDisclaimer: boolean;
    // Show the timer on the screen
    showTimer: boolean;
    // is the modal dialog shown
    modalShown: boolean;
    // should we focus the timer
    shouldFocusTimer: boolean;
    time: string;
  };
}

const initialState: CountdownState = {
  model: {
    startTime: 900,
    offerExtendTime: 120,
    showTimerTime: 120,
    secondsRemaining: 120,
    showDisclaimer: false,
    showTimer: false,
    modalShown: false,
    shouldFocusTimer: false,
    time: '',
  },
};

export const CountdownSlice = createSlice({
  name: 'countdown',
  initialState,
  reducers: {
    setCountdownModel: (state, action) => {
      state.model = action.payload;
    },
    setCountdownModelAttribute: (
      state,
      action: PayloadAction<{
        key: keyof CountdownState['model'];
        value: string | number | boolean;
      }>
    ) => {
      const { key, value } = action.payload;
      // Has to write it this way or the ts2322 error will appear
      state.model = { ...state.model, [key]: value };
    },
    // They are the same as setCountdownModel but help easier debug
    setStartTimer: (state, action) => {
      state.model = action.payload;
    },
    setTick: (state, action) => {
      state.model = action.payload;
    },
    setTimeIsUp: (state, action) => {
      state.model = action.payload;
    },
    setExtendTime: (state, action) => {
      state.model = action.payload;
    },
  },
});

export const {
  setCountdownModel,
  setCountdownModelAttribute,
  setStartTimer,
  setTick,
  setTimeIsUp,
  setExtendTime,
} = CountdownSlice.actions;

export const selectCountdown = (state: RootState) => state.countdown.model;

export default CountdownSlice.reducer;
