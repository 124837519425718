import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../app/store';
import { ApisModelInterface } from '../models/apis';
import { ModalProps } from '../models/modal';

export interface ApisState {
  model: ApisModelInterface;
  activeTab: {
    paxid: number;
    isInfant: boolean;
  };
  adcModal: {
    isOpen: boolean;
    modalData: ModalProps;
  };
}

const initialState: ApisState = {
  model: {
    apisConfirm: true,
    adcRoute: false,
    adcData: [],
    apisPaxes: [],
    apisDone: false,
    route: 'ck_apis',
    messages: {},
    canScanDocuments: false,
  },
  activeTab: {
    paxid: 1,
    isInfant: false,
  },
  adcModal: {
    isOpen: false,
    modalData: {} as ModalProps,
  },
};

export const apisSlice = createSlice({
  name: 'apis',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetApisModel: (state) => {
      return initialState;
    },
    setApisModel: (state, action) => {
      state.model = action.payload;
    },
    setApisModelAttribute: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    // In Apis model, the mom/dad and infant has same paxid
    setApisPaxAttribute: (
      state,
      action: PayloadAction<{
        paxid: number;
        isInfant: boolean;
        path: string | string[];
        value: any;
      }>
    ) => {
      const paxid = action.payload.paxid;
      const isInfant = action.payload.isInfant;
      const path = action.payload.path;
      const value = action.payload.value;
      let pax = state.model.apisPaxes.find((x) => x.paxid === paxid && x.isInfant === isInfant);
      if (pax) {
        _.set(pax, path, value);
      }
    },
    extendApisPaxAttribute: (
      state,
      action: PayloadAction<{
        paxid: number;
        isInfant: boolean;
        path: string | string[];
        object: any;
      }>
    ) => {
      const paxid = action.payload.paxid;
      const isInfant = action.payload.isInfant;
      const path = action.payload.path;
      const object = action.payload.object;
      let pax = state.model.apisPaxes.find((x) => x.paxid === paxid && x.isInfant === isInfant);
      if (pax) {
        let definedPax = pax;
        Object.keys(object).forEach((key) => {
          _.set(definedPax, path + `.${key}`, object[key]);
        });
      }
    },
    /**
     * Set key-value to all paxes
     * @param state
     * @param action
     */
    setAllApisPaxAttribute: (
      state,
      action: PayloadAction<{
        path: string | string[];
        value: any;
      }>
    ) => {
      const path = action.payload.path;
      const value = action.payload.value;
      state.model.apisPaxes.forEach((pax) => {
        _.set(pax, path, value);
      });
    },
    setApisActiveTab: (state, action: PayloadAction<{ paxid: number; isInfant: boolean }>) => {
      const paxid = action.payload.paxid;
      const isInfant = action.payload.isInfant;
      if (paxid === state.activeTab.paxid && state.activeTab.isInfant === isInfant) {
        // It mean close current tab
        state.activeTab.paxid = -1;
      } else {
        state.activeTab.paxid = paxid;
        state.activeTab.isInfant = isInfant;
      }
    },
    setDefaultAppPaxData: (state) => {
      state.model.apisPaxes.forEach((apisPax) => {
        apisPax.profileRequestTriggered = false;
        apisPax.scanDocumentTriggered = false;
      });
    },
    setApisPaxFillData: (
      state,
      action: PayloadAction<{ paxid: number; isInfant: boolean; inputChecked: boolean }>
    ) => {
      const paxid = action.payload.paxid;
      const isInfant = action.payload.isInfant;
      const inputChecked = action.payload.inputChecked;
      if (inputChecked) {
        state.model.apisPaxes.forEach((pax) => {
          pax.fillData = false;
          pax.saveProfileEnabled = false;
          if (pax.paxid === paxid && pax.isInfant === isInfant) {
            pax.fillData = true;
            pax.saveProfileEnabled = true;
          }
        });
      } else {
        state.model.apisPaxes.forEach((pax) => {
          pax.fillData = false;
          pax.saveProfileEnabled = true;
        });
      }
    },
    extendApisModel: (state, action: PayloadAction<Object>) => {
      state.model = _.extend(state.model, action.payload);
    },
    setAuthorizationType: (
      state,
      action: PayloadAction<{
        paxid: number;
        isInfant: boolean;
        value: string;
      }>
    ) => {
      const paxid = action.payload.paxid;
      const isInfant = action.payload.isInfant;
      const value = action.payload.value;
      let pax = state.model.apisPaxes.find((x) => x.paxid === paxid && x.isInfant === isInfant);
      if (pax) {
        let authorization = pax.apisData.authorization;
        authorization.authorizationType = value;

        let esta = authorization.ESTA;
        let visa = authorization.VISA;
        let greencard = authorization.GREEN_CARD;
        // check what attribute has changed
        // we need to update the authorization model
        switch (value) {
          case 'ESTA':
            if (!_.isUndefined(esta)) {
              esta.selected = true;
            }
            if (!_.isUndefined(visa)) {
              visa.selected = false;
            }
            if (!_.isUndefined(greencard)) {
              greencard.selected = false;
            }
            break;
          case 'VISA':
            if (!_.isUndefined(visa)) {
              if (!_.isUndefined(esta)) {
                esta.selected = false;
              }
              visa.selected = true;
              if (!_.isUndefined(greencard)) {
                greencard.selected = false;
              }
            }
            break;
          case 'GREEN_CARD':
            if (!_.isUndefined(greencard)) {
              if (!_.isUndefined(esta)) {
                esta.selected = false;
              }
              if (!_.isUndefined(visa)) {
                visa.selected = false;
              }
              if (!_.isUndefined(greencard)) {
                greencard.selected = true;
              }
            }
            break;
        }

        // TODO: Investigate what this `cid` is
        // var docsresRegion =
        //   this._parent._parent._regions.docsresRegion.currentView;
        // var docoRegion = this._parent._parent._regions.docoRegion.currentView;
        // if (!_.isUndefined(docsresRegion)) {
        //   authorization.set('docsResViewId', docsresRegion.cid);
        // }
        // if (!_.isUndefined(docoRegion)) {
        //   authorization.set('docoViewId', docoRegion.cid);
        // }
      }
    },
    setAdcModalOpen: (state, action: PayloadAction<boolean>) => {
      state.adcModal.isOpen = action.payload;
    },
    setAdcModalData: (state, action: PayloadAction<ModalProps>) => {
      state.adcModal.modalData = action.payload;
    },
  },
});

export const {
  setApisModel,
  setApisModelAttribute,
  setAllApisPaxAttribute,
  setApisPaxAttribute,
  extendApisPaxAttribute,
  setApisActiveTab,
  setDefaultAppPaxData,
  setApisPaxFillData,
  extendApisModel,
  setAuthorizationType,
  resetApisModel,
  setAdcModalOpen,
  setAdcModalData,
} = apisSlice.actions;

export const selectApisModel = (state: RootState) => state.apis.model;
export const selectApisActiveTab = (state: RootState) => state.apis.activeTab;
export const selectADCModalModel = (state: RootState) => state.apis.adcModal;

export default apisSlice.reducer;
