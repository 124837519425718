import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PersistGate } from 'redux-persist/integration/react';

// Lazy import to load faster and avoid using wrong scss.
const App = lazy(() => import('./App'));
const CtnApp = lazy(() => import('./overrides/ctn/CtnApp'));
const EdwApp = lazy(() => import('./overrides/edw/EdwApp'));

const getApp = () => {
  switch (process.env.REACT_APP_CUSTOMER) {
    case 'CTN':
      return <CtnApp />;
    case 'EDW':
      return <EdwApp />;
    case 'BWA':
      return <App />;
    default:
      return <App />;
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<p>Rehydrating...</p>} persistor={persistor}>
        <Suspense fallback={<p>Loading app...</p>}>{getApp()}</Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
