import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../app/store';

export interface AlertContent {
  type: 'error' | 'success' | 'warning';
  message: string;
  sessionId?: string;
}

export interface AlertModelInterface {
  [key: string]: AlertContent[];
}

export interface AlertState {
  alerts: AlertModelInterface;
}

const initialState: AlertState = {
  alerts: {},
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    /**
     * Add one single alert
     * @param state
     * @param action
     */
    addAlert: (
      state,
      action: PayloadAction<{
        name: string;
        message: any;
        type?: 'error' | 'success' | 'warning';
        sessionId?: string;
      }>
    ) => {
      let name = action.payload.name;
      let message = action.payload.message;
      let type = action.payload.type || 'error';
      let sessionId = action.payload.sessionId || '';
      if (_.isUndefined(state.alerts[name])) {
        state.alerts[name] = [];
      }

      let duped = state.alerts[name].some((alertContent) => alertContent.message === message);
      if (!duped) {
        state.alerts[name].push({
          message: message,
          type: type,
          sessionId: sessionId,
        });
      }

      if (action.payload.name.indexOf('Error') > -1) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      delete state.alerts[action.payload];
    },
    removeOneAlertContent: (state, action: PayloadAction<{ name: string; message: string }>) => {
      let alertContents = state.alerts[action.payload.name];
      alertContents.forEach((elem, index: number) => {
        if (elem.message === action.payload.message) {
          alertContents.splice(index, 1);
          return;
        }
      });
    },
    clearAlert: (state) => {
      state.alerts = {};
    },
    clearAlertExcept: (state, action: PayloadAction<string[]>) => {
      let excepts = action.payload;
      let keys = Object.keys(state.alerts);
      keys.forEach((key: string) => {
        if (excepts.indexOf(key) === -1) {
          delete state.alerts[key];
        }
      });
    },
    /**
     * Set alert / Completely replace state alert
     * @param state
     * @param action
     */
    setAlert: (state, action: PayloadAction<AlertModelInterface>) => {
      state.alerts = action.payload;
    },
    /**
     * Add multiple alerts
     * @param state
     * @param action
     */
    extendAlert: (state, action: PayloadAction<Object>) => {
      Object.assign(state.alerts, action.payload);
    },
    setAlertFromValidation: (
      state,
      action: PayloadAction<{ validationErrors: any; screentext: any }>
    ) => {
      let validationErrors = action.payload.validationErrors;
      let screentext = action.payload.screentext;
      state.alerts = parseAlertFromValidationError(validationErrors, screentext);
    },
    extendAlertFromValidation: (
      state,
      action: PayloadAction<{ validationErrors: any; screentext: any }>
    ) => {
      let validationErrors = action.payload.validationErrors;
      let screentext = action.payload.screentext;
      Object.assign(state.alerts, parseAlertFromValidationError(validationErrors, screentext));
    },
  },
});

const parseAlertFromValidationError = (validationErrors: any, screentext: any) => {
  let alertMessages: AlertModelInterface = {};
  Object.keys(validationErrors).forEach((key) => {
    let errorCode = '';
    if (typeof validationErrors[key] == 'string') {
      // Case such as : {"cor-1": "issue_contry_required_empty"}. Most common
      errorCode = validationErrors[key];
    } else {
      // Case such as : {"cor-1": Object{required: "issue_contry_required_empty"}}. Rare
      let errorObject = validationErrors[key];
      errorCode = errorObject[Object.keys(errorObject)[0]];
    }
    if (_.isUndefined(alertMessages[key])) {
      alertMessages[key] = [];
    }
    if (_.isUndefined(alertMessages[key])) {
      alertMessages[key] = [];
    }
    alertMessages[key].push({
      message: screentext[errorCode] || errorCode,
      type: 'error',
    });
  });
  return alertMessages;
};

export const {
  addAlert,
  removeAlert,
  removeOneAlertContent,
  clearAlert,
  clearAlertExcept,
  setAlert,
  extendAlert,
  setAlertFromValidation,
  extendAlertFromValidation,
} = alertSlice.actions;

export const selectErrors = (state: RootState) => state.alert.alerts;

export default alertSlice.reducer;
