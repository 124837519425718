import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../app/store';
import { ConfirmationModelInterface } from '../models/confirmation';

export interface ConfirmationState {
  model: ConfirmationModelInterface;
}

const initialState: ConfirmationState = {
  model: {
    flights: [],
    checkedInPassengers: [],
    fqtvType: '',
    fqtvValue: '',
    luggagePickup: '',
    isSingleFlight: true,
    anyBagsSelected: true,
    messages: {},
    excludeFromLocalStorage: [
      'bagDestinationMatch',
      'finalAirportDestinationISO',
      'finalAirportDestination',
    ],
    excludeFromUpdate: [
      'flights.passengers.luggageInfo',
      'flights.passengers.fqtvNumber',
      'flights.passengers.fqtvProgram',
    ],
    canCheckoutOnward: false,
    canCheckoutOutbound: true,
    finalBagDestinations: null,
    fullCkiProcess: true,
    route: 'ck_confirmation',
    routeEligibleForBagTags: false,
    paxesEligibleForBagTags: false,
    passengerClasses: ['ECONOMY_CLASS'],
    lowestAmount: null,
    upgradeAvailable: false,
    adokMessages: null,
    parsedAdokMessages: null,
    upgradeAllowed: false,
    showFinalBagsDestination: false,
  },
};

export const confirmationReducer = createSlice({
  name: 'confirmation',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetConfirmationModel: (state) => {
      return initialState;
    },
    setConfirmationModel: (state, action) => {
      state.model = action.payload;
    },
    setConfirmationModelAttribute: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    changeFqtvNumber: (
      state,
      action: PayloadAction<{ passengerId: number; fqtvNumber: string }>
    ) => {
      const passengerId = action.payload.passengerId;
      const fqtvNumber = action.payload.fqtvNumber;
      state.model.flights.forEach((flight) => {
        let passenger = flight.passengers.find((x) => x.id === passengerId);
        if (passenger) {
          passenger.fqtvNumber = fqtvNumber;
        }
      });
    },
    changeFqtvProgram: (
      state,
      action: PayloadAction<{
        passengerId: number;
        fqtvProgram: string;
      }>
    ) => {
      const passengerId = action.payload.passengerId;
      const fqtvProgram = action.payload.fqtvProgram;
      state.model.flights.forEach((flight) => {
        let passenger = flight.passengers.find((x) => x.id === passengerId);
        if (passenger) {
          passenger.fqtvProgram = fqtvProgram;
        }
      });
    },
    setConfirmationPaxAttribute: (
      state,
      action: PayloadAction<{
        paxid: number;
        key: string;
        value: any;
      }>
    ) => {
      const paxid = action.payload.paxid;
      const key = action.payload.key;
      const value = action.payload.value;
      state.model.flights.forEach((flight) => {
        let passenger = flight.passengers.find((x) => x.id === paxid);
        if (passenger) {
          _.set(passenger, key, value);
        }
      });
    },
    changeSelectedNumberOfBagTags: (
      state,
      action: PayloadAction<{
        passengerId: number;
        flightId: number;
        bagTags: number;
      }>
    ) => {
      const passengerId = action.payload.passengerId;
      const flightId = action.payload.flightId;

      const bagTags = action.payload.bagTags;
      let flight = state.model.flights.find((x) => x.id === flightId);

      if (flight) {
        let passenger = flight.passengers.find((x) => x.id === passengerId);
        if (passenger) {
          passenger.luggageInfo.selectedNumberOfBagTags = bagTags;
        }
      }
    },
    setBagTagAsSelected: (state, action: PayloadAction<{ index: number; value: boolean }>) => {
      if (state.model.finalBagDestinations) {
        state.model.finalBagDestinations[action.payload.index].selected = action.payload.value;
      }
    },
    baggageDropChanged: (state, action) => {
      let lastIdAllowed = action.payload;
      let model = state.model;
      let flightId = model.flights[0].flightId;
      let firstFlightPaxes = model.flights[0].passengers;

      if (flightId > lastIdAllowed) {
        // Set the number of selected bag tags on each passenger to 0
        // This is because we treat flights that are not in scope
        // of the baggage drop down as having no available bags for
        // purchase
        model.checkedInPassengers.forEach((pax) => {
          pax.luggageInfo.selectedNumberOfBagTags = 0;
          pax.luggageInfo.numberOfBagTags = 0;
          pax.luggageInfo.disabled = true;
        });
      } else {
        // Set the number of selected bag tags on each passenger based
        // on outbound flight
        model.checkedInPassengers.forEach((pax) => {
          let firstFligtBagTags = firstFlightPaxes[pax.id].luggageInfo.selectedNumberOfBagTags;
          pax.luggageInfo.disabled = false;
          pax.luggageInfo.selectedNumberOfBagTags = firstFligtBagTags;
        });
      }
    },
  },
});

export const {
  setConfirmationModel,
  setConfirmationModelAttribute,
  changeFqtvNumber,
  changeFqtvProgram,
  changeSelectedNumberOfBagTags,
  setBagTagAsSelected,
  baggageDropChanged,
  resetConfirmationModel,
  setConfirmationPaxAttribute
} = confirmationReducer.actions;

export const selectConfirmationModel = (state: RootState) => {
  return state.confirmation.model;
};

export default confirmationReducer.reducer;
