import _ from 'lodash';
import { ApisPaxInterface } from '../models/apis';
import { PassengerInterface } from '../models/passenger';
import { LocalizationResource } from '../services/resource/ResourceInterfaces';

/**
 * Add default key-value to each pax if such attribute is missing.
 * @param pax
 */
function initPaxAttribute(pax: PassengerInterface) {
  let defaultValue: Partial<PassengerInterface> = {
    id: 0,
    name: '',
    type: '',
    fqtvNumber: '',
    fqtvProgram: '',
    selected: false, // Pax is selected for check-in process
    ancillarySelected: false, // Pax is selected for check-in process
    nationality: '',
    cor: '', // country_of_residence
    apis: false, // is this an APIS route, used only on selectFligts screen,
    infant: [],
    alreadyCheckedIn: false, // Has the passenger been previously checked in for a specific flight
    seatReference: '', // Automatically assigned seat during checkin
    selectedSeatReference: '', // If the pax changed the seat after the checkin
    canChangeSeat: false, // New flag for seatmap,
    isPaxStaffMember: false,
    isSinglePax: false,
    emailSingle: '',
    isActive: false, // Pax is selected as active on flightbox in seatmap (radio button)
    parsedFQTVProgram: '',
    disabled: false,
  };

  Object.keys(defaultValue).forEach((key: string) => {
    let typedKey = key as keyof PassengerInterface;
    if (!(typedKey in pax)) {
      Object.assign(pax, { [typedKey]: defaultValue[typedKey] });
    }
  });
}

/**
 * Update the LocalizedPassengerType attribute and return an updated passengers array
 * @param passengers array
 * @param config
 * @param localizations
 * @returns updated passengers
 */
function updateLocalizedPassengerType(
  passengers: any,
  config: any,
  localizations: any
) {
  // I have to do a roundabout way like this because otherwise, it will occasinally
  // give an error "can not assign localizedPassengerType to read-only object"
  // when refresh browser after made change to code.
  let copiedPassengers = _.cloneDeep(passengers);
  if (copiedPassengers.length > 0) {
    _.each(copiedPassengers, function (pax: any) {
      if (pax.type === 'adult' || pax.type === 'child') {
        pax.localizedPassengerType =
          localizations[config.sharedTextGroup][pax.type];
        if (pax.infant && pax.infant.length > 0) {
          pax.infant[0].localizedPassengerType =
            localizations[config.sharedTextGroup]['infant'];
        }
      }
    });
  }
  return copiedPassengers;
}

/**
 * Mass set value for every passengers.
 * @param passengers array
 * @param prop
 * @param value
 */
function setDataToPaxes(passengers: any[], prop: any, value: any) {
  if (passengers.length > 0) {
    passengers.forEach((pax: any) => {
      pax[prop] = value;
    });
  }
}

function getPaxesForCurrentTransaction(
  passengers: any[],
  fullCkiProcess: boolean
) {
  if (fullCkiProcess) {
    return _.filter(passengers, { selected: true });
  } else {
    return _.filter(passengers, { alreadyCheckedIn: true });
  }
}

function getCurrentlySelectedPax(passengers: any[]) {
  return _.find(passengers, {
    isActive: true,
  }) as PassengerInterface;
}

function getPaxesEligibleForSeatChange(passengers: any[]) {
  return _.filter(passengers, { canChangeSeat: true });
}

function getActiveFlightPaxes(model: any) {
  let activeFlightPaxes = _.find(model.flights, {
    isActive: true,
  }).passengers;
  return activeFlightPaxes as PassengerInterface[];
}

/**
 * apis pax's apisData will be changed inplace.
 * @param apisData
 * @param localizations
 */
function initPaxApisData(apisData: any, localizations: LocalizationResource) {
  let keyToInit = [
    'authorization',
    'contactdetails',
    'cor',
    'docadest',
    'docares',
    'doco',
    'docoAdditional',
    'docsres',
  ];
  keyToInit.forEach((key: string) => {
    let typedKey = key as keyof ApisPaxInterface['apisData'];
    if (_.isUndefined(apisData[typedKey]) || _.isEmpty(apisData[typedKey])) {
      apisData[typedKey] = {};
    }
  });

  let paxType = apisData.pax.type;
  apisData.pax.localizedPassengerType = localizations.ck_shared[paxType];
}

export {
  initPaxAttribute,
  updateLocalizedPassengerType,
  setDataToPaxes,
  getPaxesForCurrentTransaction,
  getCurrentlySelectedPax,
  getPaxesEligibleForSeatChange,
  getActiveFlightPaxes,
  initPaxApisData
};
