import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { SafeTravelInterface } from '../models/safeTravel';

export interface SafeTravelState {
  model: SafeTravelInterface;
}

const initialState: SafeTravelState = {
  model: {
    route: 'ck_safetravel',
    messages: {},
    bookings: [],
    productIds: [],
    returnURL: '',
    commonPageUrl: '',
    languageMapping: [],
    handleSafeTravel: true,
  },
};

export const safeTravelSlice = createSlice({
  name: 'safeTravel',
  initialState,
  reducers: {
    setSafeTravelModel: (state, action) => {
      state.model = action.payload;
    },
    setSafeTravelModelAttributes: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
  },
});

export const { setSafeTravelModel, setSafeTravelModelAttributes } =
  safeTravelSlice.actions;

export const selectSafeTravelModel = (state: RootState) =>
  state.safetravel.model;

export default safeTravelSlice.reducer;
