import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  AirportResource,
  ConfigResource,
  CountryResource,
  FqtvResource,
  LanguageResource,
  LocalizationResource,
} from '../services/resource/ResourceInterfaces';

export interface ResourceState {
  config: ConfigResource;
  localizations: LocalizationResource;
  airports: AirportResource[];
  countries: CountryResource[];
  languages: LanguageResource[];
  fqtv: FqtvResource[];
}

const initialState: ResourceState = {
  config: {} as ConfigResource,
  localizations: {} as LocalizationResource,
  airports: [],
  countries: [],
  languages: [],
  fqtv: [],
};

export const ResourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setConfigResource: (state, action: PayloadAction<ConfigResource>) => {
      state.config = action.payload;
    },
    setLocalizationsResource: (state, action: PayloadAction<LocalizationResource>) => {
      state.localizations = action.payload;
    },
    setAirportResource: (state, action: PayloadAction<AirportResource[]>) => {
      state.airports = action.payload;
    },
    setCountryResource: (state, action: PayloadAction<CountryResource[]>) => {
      state.countries = action.payload;
    },
    setLanguageResource: (state, action: PayloadAction<LanguageResource[]>) => {
      state.languages = action.payload;
    },
    setFqtvResource: (state, action: PayloadAction<FqtvResource[]>) => {
      state.fqtv = action.payload;
    },
    setResourceKey: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state, { [key]: value });
    },
    resetResourceKey: (state, action: PayloadAction<string>) => {
      const key = action.payload as keyof ResourceState;
      Object.assign(state, { [key]: initialState[key] });
    },
    resetAllResource: (state) => {
      state.config = {} as ConfigResource;
      state.localizations = {} as LocalizationResource;
      state.airports = [];
      state.countries = [];
      state.languages = [];
      state.fqtv = [];
    },
  },
});

export const {
  setConfigResource,
  setLocalizationsResource,
  setAirportResource,
  setCountryResource,
  setLanguageResource,
  setFqtvResource,
  setResourceKey,
  resetResourceKey,
  resetAllResource,
} = ResourceSlice.actions;

export const selectConfigResource = (state: RootState) => state.resource.config;
export const selectLocalizationResource = (state: RootState) => state.resource.localizations;
export const selectAirportResource = (state: RootState) => state.resource.airports;
export const selectCountryResource = (state: RootState) => state.resource.countries;
export const selectLanguageResource = (state: RootState) => state.resource.languages;
export const selectFqtvResource = (state: RootState) => state.resource.fqtv;
export const selectResourceKey = (state: RootState, action: PayloadAction<string>) => {
  return state.resource[action.payload as keyof ResourceState];
};

export default ResourceSlice.reducer;
