import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { BoardingPassModelInterface } from '../models/boardingpass';

export interface BoardingPassState {
  model: BoardingPassModelInterface;
  gdprModal: any;
  pushTokenModal: any;
}

const initialState: BoardingPassState = {
  model: {
    flights: [],
    checkedInPassengers: [],
    subscribedPassengers: 0,
    route: 'ck_boardingpass',
    showPrintAll: false,
    bpEnabled: false,
    mbpEnabled: true,
    allInOneBoardingPassUrl: '',
    receiptUrl: '',
    retrievePassenger: {},
    encryptedDeeplink: null,
    receiptPassengerData: [],
    reprintProcess: false,
    pnrNumber: '',
    confirm_hazard: '',
    fullCkiProcess: false,
    single_pax_process: false,
    allmbp: '',
    emailSingle: '',
    messages: {},
    gdprEmailConsentAccepted: false,
    excludeFromLocalStorage: ['messages'],
    disableSendPushToken: false,
    gpaySupportedLanguages: [
      'ar',
      'cs',
      'da',
      'de',
      'en',
      'es',
      'fi',
      'fr',
      'hr',
      'in',
      'it',
      'ja',
      'ko',
      'nl',
      'no',
      'pl',
      'pt-br',
      'ru',
      'sk',
      'sv',
      'th',
      'uk',
      'zh-cn', // default to zh
      'zh-tw',
    ],
    hasWifiCode: false,
  },
  gdprModal: {
    isOpen: false,
    paxCallbackId: -1,
  },
  pushTokenModal: {
    isOpen: false,
  },
};

export const boardingpassSlice = createSlice({
  name: 'boardingpass',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetBoardingPassModel: (state) => {
      return initialState;
    },
    setBoardingPassModel: (state, action) => {
      state.model = action.payload;
    },
    setBPModelAttribute: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    setBPPaxAttribute: (state, action: PayloadAction<{ id: number; key: string; value: any }>) => {
      const id = action.payload.id;
      const key = action.payload.key;
      const value = action.payload.value;
      let pax = state.model.checkedInPassengers.find((x) => x.id === id);
      if (pax) {
        Object.assign(pax, { [key]: value });
      }
    },
    extendBPPaxAttribute: (
      state,
      action: PayloadAction<{
        id: number;
        object: any;
      }>
    ) => {
      const id = action.payload.id;
      const object = action.payload.object;
      let pax = state.model.checkedInPassengers.find((x) => x.id === id);
      if (pax) {
        Object.assign(pax, object);
      }
    },
    setCheckinPassengers: (state, action) => {
      state.model.checkedInPassengers = action.payload;
    },
    setGdprModalAttribute: (
      state,
      action: PayloadAction<{ isOpen: boolean; paxCallbackId?: number }>
    ) => {
      const isOpen = action.payload.isOpen;
      state.gdprModal.isOpen = isOpen;
      if (action.payload.paxCallbackId) {
        const paxCallbackId = action.payload.paxCallbackId;
        state.gdprModal.paxCallbackId = paxCallbackId;
      }
    },
    setPushTokenModalAttribute: (state, action: PayloadAction<{ isOpen: boolean }>) => {
      const isOpen = action.payload.isOpen;
      state.pushTokenModal.isOpen = isOpen;
    },
  },
});

export const {
  setBoardingPassModel,
  setBPModelAttribute,
  setBPPaxAttribute,
  extendBPPaxAttribute,
  setCheckinPassengers,
  setGdprModalAttribute,
  setPushTokenModalAttribute,
  resetBoardingPassModel,
} = boardingpassSlice.actions;

export const selectBoardingPassModel = (state: RootState) => state.boardingpass.model;
export const selectBPGdprModal = (state: RootState) => state.boardingpass.gdprModal;
export const selectBPPushTokenModal = (state: RootState) => state.boardingpass.pushTokenModal;
export default boardingpassSlice.reducer;
