import { configureStore, combineReducers } from '@reduxjs/toolkit';
import retrieveReducer from '../slicers/retrieve';
import selectflightReducer from '../slicers/selectflight';
import alertReducer from '../slicers/alert';
import appReducer from '../slicers/app';
import checkoutReducer from '../slicers/checkout';
import confirmationReducer from '../slicers/confirmation';
import boardingpassReducer from '../slicers/boardingpass';
import apisReducer from '../slicers/apis';
import seatmapReducer from '../slicers/seatmap';
import ancillaryReducer from '../slicers/ancillary';
import countdownReducer from '../slicers/countdown';
import securitystatementsReducer from '../slicers/securitystatements';
import safetravelReducer from '../slicers/safetravel';
import shoppingbasketReducer from '../slicers/shoppingbasket';
import flightpathReducer from '../slicers/flightpath';
import cookieReducer from '../slicers/cookie';
import resourceReducer from '../slicers/resource'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'cookie', 'resource']
};

const rootReducer = combineReducers({
  app: appReducer,
  cookie: cookieReducer,
  retrieve: retrieveReducer,
  alert: alertReducer,
  resource: resourceReducer,
  selectflight: selectflightReducer,
  checkout: checkoutReducer,
  boardingpass: boardingpassReducer,
  confirmation: confirmationReducer,
  apis: apisReducer,
  seatmap: seatmapReducer,
  ancillary: ancillaryReducer,
  countdown: countdownReducer,
  securitystatements: securitystatementsReducer,
  safetravel: safetravelReducer,
  shoppingbasket: shoppingbasketReducer,
  flightpath: flightpathReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
