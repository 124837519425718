import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { PartialPassengerInterface, PassengerInterface } from '../models/passenger';
import { SelectFlightModelInterface } from '../models/selectflight';
import edwInitialState from './initialState/selectFlight/edw.json';

export interface SelectFlightState {
  model: SelectFlightModelInterface;
}

const initialState: SelectFlightState = {
  model: {
    // list of not checked-in passengers,
    notCheckedInPassengers: [],
    // list of already checked-in passengers
    checkedInPassengers: [],
    // Will be a collection of flights
    flights: [],
    pnrNumber: '',
    // is this an APIS route
    apiData: false,
    // Flights that are for showing purposes only
    irrelevantFlights: '',
    // flag to indicate if irreg flights exist
    irr_flts_exist: false,
    // flag to indicate if valid flights exist
    vld_flts_exist: true,
    // is this normal CKI process
    fullCkiProcess: false,
    // did user confirm permission before proceeding
    confirmPermission: false,
    // did user confirm they don't carry d.g. before proceeding
    dangerousGoodsPermission: false,
    // did the user select all flights for checkin before proceeding
    checkinOnward: false,
    // are onward flights avail for CKO
    canCheckoutOnward: false,
    // is outbound flight avail for CKO
    canCheckoutOutbound: false,
    // flag to indicate if there are onward flights
    onwardExists: false,
    // flag to indicate if user can CKI to onward
    onwardEnabled: false,
    // flag to indicate if we disable proceed for CKI
    lockDownProceed: false,
    // are there only irreg flights and no CKI
    lockDownWCI: false,
    // if some pax not CKDIN show NCKDIN pax and flights
    displayNotCkd: false,
    // if all pax CKDIN to outbound hide it
    hideOutbound: false,
    // offer fqtv change for CKDIN pax
    offer_changefqtv_btn: false,
    // offer seat change for CKDIN pax
    offerChangeseatBtn: false,
    // for persisting altered COR and NAT field during add pax flow
    selectedCorAndNat: {},
    messages: {},
    excludeFromLocalStorage: ['messages'],
    profileData: {},
    retrievePassenger: {
      id: 1,
    },
    hasProfile: false,
  },
};

switch (process.env.REACT_APP_CUSTOMER) {
  case 'EDW':
    Object.assign(initialState.model, edwInitialState);
    break;
  default:
    break;
}

export const selectflightSlice = createSlice({
  name: 'selectflight',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSelectflightModel: (state) => {
      return initialState;
    },
    setSelectFlightModel: (state, action) => {
      state.model = action.payload;
    },
    setSelectFlightAttribute: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    changeFlightSelected: (state, action: PayloadAction<{ id: number; selected: boolean }>) => {
      const id = action.payload.id;
      const selected = action.payload.selected;
      let flight = state.model.flights.find((x: any) => x.id === id);
      if (flight) {
        flight.selected = selected;
      }
    },
    setSFPaxAttribute: (
      state,
      action: PayloadAction<{
        id: number;
        key: keyof PassengerInterface;
        value: PassengerInterface[keyof PassengerInterface];
      }>
    ) => {
      const id = action.payload.id;
      const key = action.payload.key;
      const value = action.payload.value;
      let pax = state.model.notCheckedInPassengers.find((x) => x.id === id);
      if (pax) {
        Object.assign(pax, { [key]: value });
        if (key === 'selected') {
          updatePassengersAfterChange(state.model);
        }
      }
    },
    setSFPaxAttributes: (
      state,
      action: PayloadAction<{ id: number; data: PartialPassengerInterface }>
    ) => {
      const id = action.payload.id;
      const data = action.payload.data;
      const keys = Object.keys(data);
      let pax = state.model.notCheckedInPassengers.find((x: PassengerInterface) => x.id === id);

      if (pax) {
        let definedPax = pax; // To avoid TypeScript annoy us
        keys.forEach((key: string) => {
          let typedKey = key as keyof PassengerInterface;
          Object.assign(definedPax, { [key]: data[typedKey] });
          if (key === 'selected') {
            updatePassengersAfterChange(state.model);
          }
        });
      }
    },
  },
});

export const {
  setSelectFlightModel,
  setSFPaxAttribute,
  setSFPaxAttributes,
  changeFlightSelected,
  setSelectFlightAttribute,
  resetSelectflightModel,
} = selectflightSlice.actions;

export const selectSelectFlightModel = (state: RootState) => state.selectflight.model;

export default selectflightSlice.reducer;

function updatePassengersAfterChange(model: SelectFlightModelInterface) {
  let notCheckedInPassengers = model.notCheckedInPassengers,
    checkedInPassengers = model.checkedInPassengers,
    flights = model.flights;

  // selecting/deselecting paxes will "enable" the profile icon so we must set this on model
  // if (
  //   notCheckedInPassengers.filter(
  //     (pax: PassengerInterface) => pax.selected === true
  //   ).length > 0
  // ) {
  //   notCheckedInPassengers.forEach((pax: PassengerInterface) => {
  //     pax.profileEnabled = false;
  //   });
  // }
  // if not all passengers are selected for checkin force checkin for pax on all flights and
  // disable the flight selection
  if (checkedInPassengers.length === 0) {
    if (
      notCheckedInPassengers.length >
      notCheckedInPassengers.filter((pax: PassengerInterface) => pax.selected === true).length
    ) {
      flights.forEach((flight: any) => {
        flight.disabled = true;
        flight.selected = true;
      });
    } else {
      flights.forEach((flight: any) => {
        flight.disabled = false;
        flight.selected = true;
      });
      flights[0].disabled = true;
    }
  }
}
