import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../app/store';
import { RetrieveModelInterface } from '../models/retrieve';
import edwInitialState from './initialState/retrieve/edw.json';

interface RetrieveState {
  model: RetrieveModelInterface;
}

const initialState: RetrieveState = {
  model: {
    documentType: 'ETIX',
    lastName: '',
    identificationNumber: '',
    dayOfDeparture: '',
    departureAirport: '',
    flightNumber: '',
    identificationType: '',
    deepLink: false,
    advancedDocumentType: false,
    etixDocumentType: false,
    retrievalSource: '',
    retrievalData: null,
    retrievalSV: '',
    excludeFromLocalStorage: [
      'addingPassengers',
      'group',
      'retrievalSV',
      'retrievalData',
      'retrievalSource',
      'firstName',
      'locale',
      'messages',
    ],
    multiplefqtvProgram: false,
    bookingRetrieveEnabled: false,
    advancedRetrieveEnabled: false,
    messages: {},
    addingPassengers: false,
    route: 'ck_retrieve',
  },
};

switch (process.env.REACT_APP_CUSTOMER) {
  case 'EDW':
    Object.assign(initialState.model, edwInitialState);
    break;
  default:
    break;
}

export const retrieveSlice = createSlice({
  name: 'retrieve',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetRetrieveModel: (state) => {
      return initialState;
    },
    setRetrieveModel: (state, action: PayloadAction<RetrieveModelInterface>) => {
      state.model = action.payload;
    },
    setRetrieveModelAttribute: (
      state,
      action: PayloadAction<{ key: keyof RetrieveModelInterface; value: any }>
    ) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state.model = { ...state.model, [key]: value };
    },
    changeDocumentType: (state, action: PayloadAction<'etix_tab' | 'advanced_tab'>) => {
      const documentType = action.payload;
      switch (documentType) {
        case 'etix_tab':
          state.model.documentType = 'ETIX';
          state.model.advancedDocumentType = false;
          state.model.etixDocumentType = true;
          break;
        case 'advanced_tab':
          state.model.documentType = 'ADVANCED';
          state.model.advancedDocumentType = true;
          state.model.etixDocumentType = false;
          break;
        default:
          break;
      }
    },

    extendRetrieveModel: (state, action: PayloadAction<Object>) => {
      state.model = _.extend(state.model, action.payload);
    },
  },
});

export const {
  setRetrieveModel,
  setRetrieveModelAttribute,
  changeDocumentType,
  resetRetrieveModel,
  extendRetrieveModel,
} = retrieveSlice.actions;

export const selectInitRetrieveModel = (state: RootState) => initialState.model;
export const selectRetrieveModel = (state: RootState) => state.retrieve.model;

export default retrieveSlice.reducer;
