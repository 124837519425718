import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface CookieState {
  model: {
    cookieAccepted: boolean;
  };
}

const initialState: CookieState = {
  model: {
    cookieAccepted: false,
  },
};

export const CookieSlice = createSlice({
  name: 'cookie',
  initialState,
  reducers: {
    setCookieModel: (state, action) => {
      state.model = action.payload;
    },
    setCookieAccept: (state, action: PayloadAction<boolean>) => {
      state.model.cookieAccepted = action.payload;
    },
    resetCookie: (state) => {
      state.model = initialState.model;
    },
  },
});

export const { setCookieModel, setCookieAccept, resetCookie } = CookieSlice.actions;

export const selectCookie = (state: RootState) => state.cookie.model;
export const selectCookieAccept = (state: RootState) => state.cookie.model.cookieAccepted;

export default CookieSlice.reducer;
