import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { CheckoutModelInterface } from '../models/checkout';

export interface CheckoutState {
  model: CheckoutModelInterface;
}

const initialState: CheckoutState = {
  model: {
    pnrNumber: '',
    route: 'ck_checkout',
    checkoutPassengers: [],
    flights: [],
    checkoutDone: false,
    canCheckoutOnward: false,
    canCheckoutOutbound: false,
    messages: {},
    encryptedDeeplink: null,
    confirmPermission: false,
  },
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCheckoutModel: (state) => {
      return initialState;
    },
    setCheckoutModel: (state, action) => {
      state.model = action.payload;
    },
    setCheckoutModelAttribute: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const key = action.payload.key;
      const value = action.payload.value;
      Object.assign(state.model, { [key]: value });
    },
    changeCheckoutPaxAttribute: (
      state,
      action: PayloadAction<{ id: number; key: string; value: any }>
    ) => {
      const id = action.payload.id;
      const key = action.payload.key;
      const value = action.payload.value;
      let pax = state.model.checkoutPassengers.find((x) => x.id === id);
      if (pax) {
        Object.assign(pax, { [key]: value });
      }
    },
  },
});

export const {
  setCheckoutModel,
  setCheckoutModelAttribute,
  changeCheckoutPaxAttribute,
  resetCheckoutModel,
} = checkoutSlice.actions;

export const selectCheckoutModel = (state: RootState) => state.checkout.model;

export default checkoutSlice.reducer;

export { initialState };
